@import "src/shared";

.modal {
  width: 100% !important;
  max-width: none !important;

  @media only screen and (min-width: $media-desktop-s)  {
    width: calc(100% - 250px) !important;
    overflow: auto;
  }
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterHeaderLeft, .filter {
  display: flex;
  align-items: center;
}

.filterText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color-neutral-neutral-6 !important;
  margin-left: 8px;
}

.filterName {
  border: 1px solid #434343;
  border-radius: 2px;
  cursor: pointer;
  padding: 1px 8px;
  margin-left: 12px;
}

.filterIcon {
  svg {
    fill: $color-neutral-neutral-6;
  }
}

.navbar {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.tab {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color-neutral-neutral-6 !important;
  cursor: pointer;
  padding: 12px 0;
  margin: 0 24px;
  pointer-events: none;
}

.tabSelected {
  pointer-events: none;
  color: $color-primary-primary-6 !important;
  border-bottom: 1px solid $color-primary-primary-6 !important;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
}

.card {
  cursor: pointer;
  margin: 16px;
}

.sortIcon {
  svg {
    fill: $color-primary-primary-6;
  }
}