@import 'src/shared';

.tag {
    font-weight: 400;
    margin-left: 12px;
    background-color: $color-neutral-neutral-2;
    border-color: $color-neutral-neutral-5;

    &Primary {
        color: $color-primary-primary-6;
    }

    &Secondary {
        color: $color-neutral-neutral-8;
    }
}
