$color-neutral-neutral-1: rgba(20, 20, 20, 1);
$color-neutral-neutral-2: rgba(29, 29, 29, 1);
$color-neutral-neutral-3: rgba(38, 38, 38, 1);
$color-neutral-neutral-4: rgba(48, 48, 48, 1);
$color-neutral-neutral-5: rgba(67, 67, 67, 1);
$color-neutral-neutral-6: rgba(90, 90, 90, 1);
$color-neutral-neutral-7: rgba(125, 125, 125, 1);
$color-neutral-neutral-8: rgba(172, 172, 172, 1);
$color-neutral-neutral-9: rgba(219, 219, 219, 1);
$color-neutral-neutral-10: rgba(255, 255, 255, 1);
$color-primary-primary-1: rgba(61, 49, 0, 1);
$color-primary-primary-2: rgba(82, 66, 0, 1);
$color-primary-primary-3: rgba(123, 99, 0, 1);
$color-primary-primary-4: rgba(163, 132, 0, 1);
$color-primary-primary-5: rgba(204, 165, 0, 1);
$color-primary-primary-6: rgba(245, 201, 19, 1);
$color-primary-primary-7: rgba(255, 217, 62, 1);
$color-primary-primary-8: rgba(255, 225, 104, 1);
$color-primary-primary-9: rgba(255, 234, 147, 1);
$color-primary-primary-10: rgba(255, 242, 189, 1);
$color-red-red-1: rgba(42, 18, 21, 1);
$color-red-red-2: rgba(67, 20, 24, 1);
$color-red-red-3: rgba(88, 24, 28, 1);
$color-red-red-4: rgba(121, 26, 31, 1);
$color-red-red-5: rgba(166, 29, 36, 1);
$color-red-red-6: rgba(211, 32, 41, 1);
$color-red-red-7: rgba(232, 71, 73, 1);
$color-red-red-8: rgba(243, 115, 112, 1);
$color-red-red-9: rgba(248, 159, 154, 1);
$color-red-red-10: rgba(250, 200, 195, 1);
$color-volcano-volcano-1: rgba(43, 22, 17, 1);
$color-volcano-volcano-2: rgba(68, 29, 18, 1);
$color-volcano-volcano-3: rgba(89, 39, 22, 1);
$color-volcano-volcano-4: rgba(124, 49, 24, 1);
$color-volcano-volcano-5: rgba(170, 62, 25, 1);
$color-volcano-volcano-6: rgba(216, 74, 27, 1);
$color-volcano-volcano-7: rgba(232, 112, 64, 1);
$color-volcano-volcano-8: rgba(243, 149, 106, 1);
$color-volcano-volcano-9: rgba(248, 182, 146, 1);
$color-volcano-volcano-10: rgba(250, 212, 188, 1);
$color-sunset-orange-orange-1: rgba(43, 29, 17, 1);
$color-sunset-orange-orange-2: rgba(68, 42, 17, 1);
$color-sunset-orange-orange-3: rgba(89, 56, 21, 1);
$color-sunset-orange-orange-4: rgba(124, 74, 21, 1);
$color-sunset-orange-orange-5: rgba(216, 122, 22, 1);
$color-sunset-orange-orange-6: rgba(216, 122, 22, 1);
$color-sunset-orange-orange-7: rgba(232, 154, 60, 1);
$color-sunset-orange-orange-8: rgba(243, 183, 101, 1);
$color-sunset-orange-orange-9: rgba(248, 207, 141, 1);
$color-sunset-orange-orange-10: rgba(250, 227, 183, 1);
$color-calendula-gold-gold-1: rgba(43, 33, 17, 1);
$color-calendula-gold-gold-2: rgba(68, 49, 17, 1);
$color-calendula-gold-gold-3: rgba(89, 66, 20, 1);
$color-calendula-gold-gold-4: rgba(124, 89, 20, 1);
$color-calendula-gold-gold-5: rgba(170, 119, 20, 1);
$color-calendula-gold-gold-6: rgba(216, 150, 20, 1);
$color-calendula-gold-gold-7: rgba(232, 179, 57, 1);
$color-calendula-gold-gold-8: rgba(243, 204, 98, 1);
$color-calendula-gold-gold-9: rgba(248, 223, 139, 1);
$color-calendula-gold-gold-10: rgba(250, 237, 181, 1);
$color-sunrise-yellow-yellow-1: rgba(43, 38, 17, 1);
$color-sunrise-yellow-yellow-2: rgba(68, 59, 17, 1);
$color-sunrise-yellow-yellow-3: rgba(68, 59, 17, 1);
$color-sunrise-yellow-yellow-4: rgba(124, 110, 20, 1);
$color-sunrise-yellow-yellow-5: rgba(170, 149, 20, 1);
$color-sunrise-yellow-yellow-6: rgba(216, 189, 20, 1);
$color-sunrise-yellow-yellow-7: rgba(232, 214, 57, 1);
$color-sunrise-yellow-yellow-8: rgba(243, 234, 98, 1);
$color-sunrise-yellow-yellow-9: rgba(248, 244, 139, 1);
$color-sunrise-yellow-yellow-10: rgba(250, 250, 181, 1);
$color-lime-lime-1: rgba(31, 38, 17, 1);
$color-lime-lime-2: rgba(46, 60, 16, 1);
$color-lime-lime-3: rgba(62, 79, 19, 1);
$color-lime-lime-4: rgba(83, 109, 19, 1);
$color-lime-lime-5: rgba(111, 148, 18, 1);
$color-lime-lime-6: rgba(139, 187, 17, 1);
$color-lime-lime-7: rgba(169, 209, 52, 1);
$color-lime-lime-8: rgba(201, 231, 93, 1);
$color-lime-lime-9: rgba(228, 248, 139, 1);
$color-lime-lime-10: rgba(240, 250, 181, 1);
$color-green-green-1: rgba(4, 102, 0, 1);
$color-green-green-2: rgba(5, 142, 0, 1);
$color-green-green-3: rgba(7, 183, 0, 1);
$color-green-green-4: rgba(9, 224, 1, 1);
$color-green-green-5: rgba(31, 255, 23, 1);
$color-green-green-6: rgba(71, 255, 64, 1);
$color-green-green-7: rgba(112, 255, 106, 1);
$color-green-green-8: rgba(152, 255, 148, 1);
$color-green-green-9: rgba(192, 255, 190, 1);
$color-green-green-10: rgba(232, 255, 232, 1);
$color-cyan-cyan-1: rgba(17, 33, 35, 1);
$color-cyan-cyan-2: rgba(17, 53, 54, 1);
$color-cyan-cyan-3: rgba(20, 72, 72, 1);
$color-cyan-cyan-4: rgba(20, 98, 98, 1);
$color-cyan-cyan-5: rgba(19, 133, 133, 1);
$color-cyan-cyan-6: rgba(19, 168, 168, 1);
$color-cyan-cyan-7: rgba(51, 188, 183, 1);
$color-cyan-cyan-8: rgba(88, 209, 201, 1);
$color-cyan-cyan-9: rgba(132, 226, 216, 1);
$color-cyan-cyan-10: rgba(178, 241, 232, 1);
$color-blue-geekblue-1: rgba(0, 49, 92, 1);
$color-blue-geekblue-2: rgba(0, 71, 133, 1);
$color-blue-geekblue-3: rgba(0, 93, 173, 1);
$color-blue-geekblue-4: rgba(0, 115, 214, 1);
$color-blue-geekblue-5: rgba(0, 137, 255, 1);
$color-blue-geekblue-6: rgba(46, 158, 255, 1);
$color-blue-geekblue-7: rgba(92, 179, 255, 1);
$color-blue-geekblue-8: rgba(138, 200, 255, 1);
$color-blue-geekblue-9: rgba(183, 222, 255, 1);
$color-blue-geekblue-10: rgba(229, 243, 255, 1);
$color-purple-purple-1: rgba(69, 0, 92, 1);
$color-purple-purple-2: rgba(100, 0, 133, 1);
$color-purple-purple-3: rgba(131, 0, 173, 1);
$color-purple-purple-4: rgba(161, 0, 214, 1);
$color-purple-purple-5: rgba(192, 0, 255, 1);
$color-purple-purple-6: rgba(203, 46, 255, 1);
$color-purple-purple-7: rgba(214, 92, 255, 1);
$color-purple-purple-8: rgba(226, 138, 255, 1);
$color-purple-purple-9: rgba(237, 183, 255, 1);
$color-purple-purple-10: rgba(248, 229, 255, 1);
$color-magenta-magenta-1: rgba(41, 19, 33, 1);
$color-magenta-magenta-2: rgba(64, 22, 47, 1);
$color-magenta-magenta-3: rgba(85, 28, 59, 1);
$color-magenta-magenta-4: rgba(117, 32, 79, 1);
$color-magenta-magenta-5: rgba(160, 38, 105, 1);
$color-magenta-magenta-6: rgba(203, 43, 131, 1);
$color-magenta-magenta-7: rgba(224, 82, 156, 1);
$color-magenta-magenta-8: rgba(243, 127, 183, 1);
$color-magenta-magenta-9: rgba(248, 168, 204, 1);
$color-magenta-magenta-10: rgba(250, 210, 227, 1);
$color-extra-background-1: rgba(240, 242, 245, 1);
$color-extra-background-2: rgba(0, 21, 41, 1);
$color-extra-background-3: rgba(0, 12, 23, 1);
