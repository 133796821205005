@import "src/shared";

.mineAreaInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 32px;
}

.info {
  flex: 1;

  &:first-child {
    margin-right: 34px;
  }
}

.initialInfo {
  min-height: 428px;
}

.title {
  padding-bottom: 0.5em;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.subInfo {
  margin-top: 24px;
}