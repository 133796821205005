@import "src/shared";

.wrapperForTittleWithRightSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: $media-desktop-s)  {
    margin-bottom: 36px;
  }
}

.data {
  background-color: $color-neutral-neutral-1;
  border: 1px solid $color-neutral-neutral-4;
  border-radius: 2px;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid $color-neutral-neutral-4;

  &:last-child {
    border-bottom: none;
  }
}

.firsColumn {
  border-right: 1px solid $color-neutral-neutral-4;
  margin-bottom: 16px;
}

.grid {
  border-bottom: 1px solid $color-neutral-neutral-4;
  padding-bottom: 27px;
  margin-bottom: 27px;

  @media only screen and (min-width: $media-desktop-s)  {
    border-bottom: none;
  }
}

.infosWrapper {
  gap: 18px;
  display: grid;
  margin-bottom: 18px;
}
