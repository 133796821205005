@import "src/shared";

.container {
  max-width: 865px;
  margin: 0 auto;
}

.title {
  font-family: $orbitron;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
}

.content {
  padding-top: 32px;
}