@import "src/shared";

.container {
  max-width: 300px;
  width: 100%;
  margin: 15% auto 0;
}

.timerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  @media only screen and (min-width: $media-desktop-s)  {
    padding-top: 24px;
  }
}

.timer {
  padding-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  @media only screen and (min-width: $media-desktop-s)  {
    font-size: 20px;
    line-height: 28px;
  }
}
