.background {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    z-index: -1;
    background: url("../../shared/ui/images/contractor/dark-gray.webp") no-repeat right center fixed;
    background-size: cover;
  }
}
