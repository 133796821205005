@import 'src/shared';

.title {
  padding-bottom: 2px;

  @media only screen and (min-width: $media-desktop-s) {
    padding-bottom: 16px;
  }
}

.timerContainer {
  color: $color-neutral-neutral-10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  svg {
    fill: $color-neutral-neutral-10;
  }

  @media only screen and (min-width: $media-desktop-s)  {
    padding-top: 24px;
  }
}

.timer {
  padding-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  @media only screen and (min-width: $media-desktop-s)  {
    font-size: 16px;
    line-height: 24px;
  }
}