.message {
    padding: 15px 26px;
    width: 416px;
    height: 160px;
    background-image: url("../../images/common/stub-message-block-bg.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.title {
    text-transform: uppercase;
}
