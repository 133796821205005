@import "src/shared";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: $color-neutral-neutral-2;
  min-height: 100vh;
  font-family: $bai;
  color: $color-neutral-neutral-9;
}

.content {
  flex-grow: 1;
  overflow-y: auto;

  &WithPaddings {
    padding: 56px 16px;

    @media only screen and (min-width: $media-desktop-s)  {
      padding: 44px 56px;
    }
  }
}
