// stylelint-disable
@import "src/shared";

$deprecation-bar-color-common: linear-gradient(90deg, #6EC005 0%, #A9E301 30%, #F1CE18 60%, #FF7A00 80%, #E70000 100%);
$deprecation-bar-color-uncommon: linear-gradient(90deg, #6EC005 0%, #A9E301 35%, #F1CE18 65%, #FF7A00 80%, #E70000 100%);
$deprecation-bar-color-rare: linear-gradient(90deg, #6EC005 0%, #A9E301 45%, #F1CE18 60%, #FF7A00 80%, #E70000 100%);
$deprecation-bar-color-epic: linear-gradient(90deg, #6EC005 0%, #A9E301 55%, #F1CE18 70%, #FF7A00 85%, #E70000 100%);
$deprecation-bar-color-legendary: linear-gradient(90deg, #6EC005 0%, #A9E301 65%, #F1CE18 80%, #FF7A00 90%, #E70000 100%);

.Common {
  :global {
    .ant-progress-inner {
      background: $deprecation-bar-color-common;
    }
  }
}

.Uncommon {
  :global {
    .ant-progress-inner {
      background: $deprecation-bar-color-uncommon;
    }
  }
}

.Rare {
  :global {
    .ant-progress-inner {
      background: $deprecation-bar-color-rare;
    }
  }
}

.Epic {
  :global {
    .ant-progress-inner {
      background: $deprecation-bar-color-epic;
    }
  }
}

.Legendary {
  :global {
    .ant-progress-inner {
      background: $deprecation-bar-color-legendary;
    }
  }
}


.progress > div > div {
  background-color: $color-neutral-neutral-6;
}

.progress {
  display: flex;
  align-items: center;

  :global {
    .ant-progress-outer {
      margin: 0 !important;
      padding: 0 !important;
      flex: 1;
    }

    .ant-progress-text {
      width: fit-content !important;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: $color-neutral-neutral-7;
  width: 100%;
}

.rightContent {
  padding-left: 8px;
}

.rootDeprecation {
  :global {
    .ant-progress-inner {
      overflow: initial;
    }

    .ant-progress-bg {
      background-color: $color-blue-geekblue-3;
    }

    .ant-progress-success-bg {
      background-color: transparent;

      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 16px;
        background-color: $color-neutral-neutral-10;
        right: 0;
        top: -4px;
        border-radius: 2px;
      }
    }
  }
}