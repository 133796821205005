@import "src/shared";

.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $color-neutral-neutral-9;
  font-family: $orbitron;

  @media only screen and (min-width: $media-desktop-s) {
    font-size: 20px;
    line-height: 28px;
  }
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: $color-neutral-neutral-9;
  font-family: $bai;

  @media only screen and (min-width: $media-desktop-s) {
    font-size: 14px;
    line-height: 22px;
  }
}
