// stylelint-disable declaration-block-no-redundant-longhand-properties
@import 'src/shared';

.avatar {
  cursor: pointer
}

.wrapper {
  display: flex;
  align-items: center;
  color: $color-neutral-neutral-10
}

.userMenuHeader {
  color: $color-neutral-neutral-8;
}

.name {
  color: $color-neutral-neutral-9 !important;
}

.energyCount {
  color: $color-neutral-neutral-10;
  margin-left: 7px;
  margin-right: 16px;
  font-size: 12px;
}

.attrs {
  margin-top: 12px;
  min-width: 225px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: $color-neutral-neutral-8;
}

.dataUnitTitle {
  color: $color-neutral-neutral-10 !important;
  margin-bottom: 0 !important;
}

.button {
  width: 100%;
}

.buttonWrapper {
  padding: 16px;
}

.item {
  padding: 11px 10px 0;
}

.location {
  color: $color-neutral-neutral-9;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px 0;
  grid-template-areas:
    ". ."
    ". .";
  color: $color-neutral-neutral-7;
  padding-left: 16px;
  margin-bottom: 16px;
}

.settingsIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 21px;
  color: $color-neutral-neutral-9
}
