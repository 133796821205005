@import 'src/shared';

.equipmentHall {
    @include enter-hover;

    background-image: url('../../../../../shared/ui/images/engineer/center.webp');
    height: 50vh;
    width: 70vw;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    z-index: 1;

    &:not(.disabled) {
        cursor: pointer;
    }

    @media (min-width: $media-tablet) {
        filter: initial;
        height: 70vh;
        width: 50vw;
        background-position: center;
    }

    &.disabled {
        filter: initial;
    }
}

.tooltip {
    z-index: 1;
}
