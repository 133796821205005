@import "src/shared";

.content {
  margin: 8px 73px 10px;
}

.title {
  color: $color-neutral-neutral-8 !important;
  display: block;
  padding-bottom: 8px;
}
