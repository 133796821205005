@import 'src/shared';

.engageArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  padding-bottom: 13px;

  @media only screen and (min-width: $media-desktop-s) {
    padding-bottom: 8px;
  }
}

.description {
  padding-bottom: 0;

  @media only screen and (min-width: $media-desktop-s) {
    padding-bottom: 16px;
  }
}
