.content {
    background-image: url("../../shared/ui/images/common/404.webp");
    background-size: cover;
    background-repeat: no-repeat;
}

.message {
    position: absolute;
    right: 150px;
    bottom: 180px;
}
