@import 'src/shared';

.root {
    height: 100vh;
    width: 100vw;
    background: #0c2529;
}

.header {
    height: auto;
    line-height: initial;
    background: none;
    z-index: 3;
}

.content {
    color: $color-neutral-neutral-9;
    z-index: 1;
}

.footer {
    padding: 12px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    z-index: 2;

    @media only screen and (min-width: $media-desktop-s) {
        padding: 24px;
    }
}
