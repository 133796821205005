@import 'src/shared';

.row {
    margin-bottom: 8px;
}

.left {
    max-width: 100px;
    margin-left: auto;
}

.preview {
    margin-bottom: 4px;
}

.type {
    margin-bottom: 8px;
    text-align: center;
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px 7px;
    color: #fff;
    background-color: rgba($color-neutral-neutral-1, 0.7);
}

.table {
    border: 1px solid $color-neutral-neutral-4;
    border-bottom: none;
}
