.message {
    position: absolute;
    left: 51px;
    top: 88px;
}

.video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
