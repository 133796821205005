@import "src/shared";

.modalContent {
  padding-top: 20%;
  text-align: center;
  padding-bottom: 20%;
}

.button {
  width: 70%;
  margin: auto;
}

.button:first-child {
  margin-bottom: 16px;
}