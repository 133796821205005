@import "src/shared";

.link {
    color: $color-primary-primary-6;

    &:hover,
    &:focus {
        color: $color-primary-primary-5;
    }

    &:active {
        color: $color-primary-primary-7;
    }
}
