// stylelint-disable scss/at-import-partial-extension
@import "../styles.module";
@import "src/shared";

.background {
  composes: deck;
  background-color: $color-neutral-neutral-4;
}

.disabled {
  opacity: .5;
}

.disabledIcon {
  opacity: .1;
}