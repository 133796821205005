@import "src/shared";

@keyframes pulse {
  25% {
    filter: grayscale(25%);
    opacity: 0.5;
    transform: scale(0.995);
  }

  50% {
    filter: grayscale(50%);
    transform: scale(1.001);
    opacity: 0.75;
  }

  75% {
    filter: grayscale(75%);
    opacity: 0.9;
    transform: scale(0.995);
  }

  100% {
    filter: grayscale(50%);
    transform: scale(1);
    opacity: 1;
  }
}

.card {
  display: inline-block;
  margin-right: 16px;

  @media (min-width: $media-desktop-s)  {
    margin-right: 32px;
  }
}

.cardColumn {
  border: 1px solid $color-neutral-neutral-6;
  border-radius: 4px;
  z-index: 1;
  padding: 22px;


  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .7;
    z-index: -1;
    background: $color-neutral-neutral-1;
  }
}

.cardColumnTooltip {
  max-width: 465px;

  :global(.ant-tooltip-inner) {
      color: $color-neutral-neutral-1;
  }
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 28px;
}

.draggedElements {
  animation: pulse 3s linear infinite;
  composes: cardsWrapper;
}

.space {
  width: 100%;
}
