@import "src/shared";

$time: 1;

.dankAssLoader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    display: flex;
  }
}

.arrowBase {
  width: 0;
  height: 0;

  @for $i from 1 through 18 {
    &.outer#{$i} {
      animation-delay: -($time / 18) * $i + s;
    }
  }

  @for $i from 1 through 6 {
    &.inner#{$i} {
      animation-delay: -($time / 6) * $i + s;
    }
  }

  &.down {
    transform: rotate(180deg);
  }
}

.arrow {
  $size: 3px;

  composes: arrowBase;
  margin: 0 (-$size / 2);
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  animation: blink $time + s infinite;
  border-bottom: ($size * 1.8) solid $color-primary-primary-6;
  filter: drop-shadow(0 0 ($size * 1.5) $color-primary-primary-6);
}

.arrowBig {
  $size: 10px;

  composes: arrowBase;
  margin: 0 (-$size / 2);
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  animation: blink $time + s infinite;
  border-bottom: ($size * 1.8) solid $color-primary-primary-6;
  filter: drop-shadow(0 0 ($size * 1.5) $color-primary-primary-6);
}

@keyframes blink {
  0% { opacity: 0.1; }
  30% { opacity: 1; }
  100% { opacity: 0.1; }
}