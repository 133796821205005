@import "src/shared";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: $color-neutral-neutral-9;
  cursor: pointer;
  font-family: "Bai Jamjuree", sans-serif;
}