@import 'src/shared';

.buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.occupiedTable {
    margin-top: 20px;
}