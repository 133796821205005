@import 'src/shared';

.monitor {
    position: fixed;
    height: 207px;
    width: 185px;
    text-align: center;
    background-image: url("../../../../../shared/ui/images/engineer/main-display.webp");
    background-size: contain;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 5px 20px;
    display: flex;

    @media (min-width: $media-tablet) {
        width: 380px;
        height: 320px;
        padding: 120px 50px 50px;
        background-size: cover;
        background-position: bottom center;
        left: 7vh;
        display: block;
    }

    @media (min-width: $media-desktop-s) {
        width: 420px;
        height: 412px;
        padding: 160px 50px 35px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
