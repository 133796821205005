@import "src/shared/ui/variables/colors";

@mixin effect-style-elevation {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

@mixin effect-style-input-default-focus {
  box-shadow: 0 0 4px rgba(24, 144, 255, 0.5);
}

@mixin effect-style-input-error-focus {
  box-shadow: 0 0 4px rgba(245, 34, 45, 0.5);
}

@mixin effect-style-input-warning-focus {
  box-shadow: 0 0 4px rgba(250, 173, 20, 0.5);
}

@mixin enter-hover {
  @keyframes glitch {
    0% {
      filter: drop-shadow(0 0 20px $color-neutral-neutral-10)
    }

    50% {
      filter: drop-shadow(0 0 18px $color-neutral-neutral-10);
    }

    100% {
      filter: drop-shadow(0 0 20px $color-neutral-neutral-10);
    }
  }

  &:hover {
    animation: glitch 2s infinite;
  }

  &:hover .enterLink {
    background-color: $color-primary-primary-6;
  }
}

