@import 'src/shared';

.card {
    display: flex;
    align-content: center;
    justify-content: center;
    height: 138px;
    width: 88px;
    border: 1px solid $color-neutral-neutral-5;
    border-radius: 2px;
    cursor: pointer;
}

.card.disabled {
    cursor: default;
}

.card.notAllowed {
    user-select: none;
    cursor: not-allowed;
}

.icon.notAllowed {
    color: $color-neutral-neutral-6;
}

.icon {
    margin: auto;
}