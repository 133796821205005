@import 'src/shared';

.title {
  padding-bottom: 4px;

  @media only screen and (min-width: $media-desktop-s) {
    padding-bottom: 12px;
  }
}

.actionButton {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}