@import 'src/shared';

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
}

h4.title, h1.title {
  margin-bottom: 0;
  color: $color-neutral-neutral-9 !important;
}

.iconAndTitleWrapper {
  color: $color-neutral-neutral-9;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and (min-width: $media-desktop-s)  {
    display: none;
  }
}

.backButtonAndTitleWrapper {
  justify-content: space-between;
  padding: 36px 56px;
  display: none;
  
  @media only screen and (min-width: $media-desktop-s)  {
    display: flex;
  }
}

.backArrow {
  margin-right: 11px;
}

.cityIcon {
  display: flex;
  justify-self: center;
}

.city {
  color: white !important;
}
