@import "src/shared";

.container {
  max-width: 350px;
  margin: 0 auto;
  padding: 0 15px;
}

.title {
  padding-top: 12px;

  @media only screen and (min-width: $media-desktop-s)  {
    padding-top: 50px;
  }
}

.description {
  padding-top: 6px;
  max-width: 320px;

  @media only screen and (min-width: $media-desktop-s)  {
    padding-top: 19px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;

  @media only screen and (min-width: $media-desktop-s)  {
    padding-top: 28px;
  }
}
