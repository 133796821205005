@import "src/shared";

.container {
  padding: 12px 19px;

  @media only screen and (min-width: $media-desktop-s) {
    padding: 28px 24px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  padding: 0;
  margin: 0 auto;

  @media only screen and (min-width: $media-desktop-s) {
    padding-right: 20px;
  }
}

.title {
  padding-bottom: 10px;

  @media only screen and (min-width: $media-desktop-s) {
    padding-bottom: 13px;
  }
}

.description {
  padding-top: 0;
  text-align: center;
  max-width: 320px;

  @media only screen and (min-width: $media-desktop-s) {
    padding-top: 15px;
    text-align: left;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;

  > div {
    cursor: pointer;
    padding: 4px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media only screen and (min-width: $media-desktop-s) {
    padding-top: 24px;
  }
}

.button {
  padding: 0;
}

.secondButton {
  text-align: right;
}