@import 'src/shared';

.selectedItem {
    padding: 4px 12px;
    background-color: $color-neutral-neutral-1;
    border: 1px solid $color-neutral-neutral-5;
    border-radius: 2px;
}

.selectedItemWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
}
