@import "src/shared";

.icon svg {
  color: $color-green-green-6;
  font-size: 64px;
}

.wide {
  width: 100%;
}
