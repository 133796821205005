@import "src/shared";

.cabinBackground {
  font-family: $bai;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../shared/ui/images/contractor/dark-gray.webp") no-repeat right center fixed;
  background-color: $color-extra-background-3;
  background-size: cover;

  &DarkRed {
    background: url("../../../shared/ui/images/contractor/dark-red.webp") no-repeat right center fixed;
    background-size: cover;
  }

  &LightGreen {
    background: url("../../../shared/ui/images/contractor/light-green.webp") no-repeat right center fixed;
    background-size: cover;
  }

  &LightRed {
    background: url("../../../shared/ui/images/contractor/light-red.webp") no-repeat right center fixed;
    background-size: cover;
  }

  &LightYellow {
    background: url("../../../shared/ui/images/contractor/light-yellow.webp") no-repeat right center fixed;
    background-size: cover;
  }

  @media only screen and (min-width: $media-desktop-s) {
    position: relative;
    min-height: 100vh;
  }
}

.cabinMonitorContainerHeight {
  width: 100vw;

  @media (min-width: $media-mobile-oriental) {
    width: 71vh;
    left: 15.3vh;
  }
}

.cabinMonitorContainerWidth {
  width: 71vh;
  left: 8vw;
}

.title {
  font-family: $orbitron;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: $color-neutral-neutral-9;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: $media-desktop-s) {
    font-size: 20px;
    line-height: 28px;
  }
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $color-neutral-neutral-9;

  @media only screen and (min-width: $media-desktop-s) {
    font-size: 14px;
    line-height: 22px;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chair {

  @media only screen and (min-width: $media-desktop-s) {
    @include enter-hover;

    background: url('../../../shared/ui/images/contractor/chair.webp') no-repeat;
    background-size: contain;
    height: 49vh;
    width: 14vw;
    position: absolute;
    bottom: 20vh;
    right: 30vw;
    cursor: pointer;
  }
}

.monitors {

  @media only screen and (min-width: $media-desktop-s) {
    @include enter-hover;

    background: url('../../../shared/ui/images/contractor/monitors.webp') no-repeat;
    background-size: contain;
    height: 40vh;
    width: 12vw;
    position: absolute;
    bottom: 18vh;
    right: 20vw;
    cursor: pointer;
  }
}

.stuff {

  @media only screen and (min-width: $media-desktop-s) {
    @include enter-hover;

    background: url('../../../shared/ui/images/contractor/stuff.webp') no-repeat;
    background-size: contain;
    height: 22vh;
    width: 23vw;
    position: absolute;
    bottom: 0;
    right: 30vw;
    cursor: pointer;
  }
}