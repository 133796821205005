@import "src/shared";

.cabin {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.cabinBackground {
  z-index: -1;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.monitor {
  max-width: 25.5vw;
  padding-left: 0.5vw;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}