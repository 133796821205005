// stylelint-disable scss/at-import-partial-extension
@import "src/shared";

.modal {
  font-family: $bai;
  padding: 0;
}

.wideOnMobile {
  max-width: 100vh;
  margin: 0;
  top: 0;

  @media only screen and (min-width: $media-desktop-s)  {
    max-width: calc(100vw - 32px);
    padding: 0 0 24px;
    top: 100px;
  }
}
