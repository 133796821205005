.box {
  max-width: 200px;
  margin-bottom: 53px;
  margin-top: 56px;
}

.cards {
  max-width: 220px;
  max-height: 160px;
  margin-bottom: 55px;
  margin-left: 35px;
  margin-top: 38px;
}

.selectButton {
  margin-bottom: 20px;
}
