@import "src/shared";

.box {
  @include enter-hover;

  position: fixed;
  left: 16px;
  bottom: 18px;
  cursor: pointer;

  @media (min-width: $media-tablet)  {
    left: 101px;
    bottom: 40px;
  }
}