@import "src/shared";

@mixin background-shared-style {
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  cursor: pointer;
}

.wasteland {
  background-repeat: no-repeat;
  background-color: $color-lime-lime-4;
  background-image: url("../../shared/ui/images/wasteland/wastelands.webp");
  height: 100vh;
  max-width: 100vw;
  font-family: $orbitron;
  background-size: cover;
}

h5.enterLink {
  padding: 4px 12px;
  display: inline-block;
  color: $color-neutral-neutral-10;
  cursor: pointer;
  background: rgba(20, 20, 20, 0.4);
  border-bottom: 1px solid $color-neutral-neutral-1;
  font-family: $orbitron;
}

.city {
  @include background-shared-style;
  @include enter-hover;

  background-image: url("../../shared/ui/images/wasteland/city.webp");
  height: 25vh;
  width: 20vw;
  margin: auto;

  @media (min-width: $media-tablet)  {
    filter: initial;
  }
}

.cityTitle {
  margin-top: 15%;
  margin-left: -35%;
}

.hub {
  position: absolute;
  height: 40vh;
  width: 30vw;
  background-image: url("../../shared/ui/images/wasteland/hub.webp");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}

.mineDeck {
  @include background-shared-style;
  @include enter-hover;

  position: absolute;
  right: 0;
  top: 20vh;
  background-image: url("../../shared/ui/images/wasteland/deck.webp");
  height: 50vh;
  width: 50vw;

  @media (min-width: $media-tablet)  {
    filter: initial;
  }
}

.mineDeckLink {
  float: right;
  margin-top: 10%;
  margin-right: 10%;
}

.mine {
  @include background-shared-style;
  @include enter-hover;

  position: absolute;
  bottom: 0;
  right: 15vh;
  left: 0;
  margin: auto;
  background-image: url("../../shared/ui/images/wasteland/mine.webp");
  height: 50vh;
  width: 50vw;

  @media (min-width: $media-tablet)  {
    filter: initial;
  }
}

.mineTitle {
  margin: 15%;
}
