@import "src/shared";

.cabinWrapper {
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-image: url('../../../../shared/ui/images/mineowner/mine-owner-cabin-default.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-color: $color-cyan-cyan-3;
}

.cabinDefaultInside {
  background-image: url('../../../../shared/ui/images/mineowner/mine-owner-cabin-default-light.webp');
}

.incident {
  background-image: url('../../../../shared/ui/images/mineowner/mine-owner-cabin-incident.webp');
}

.panel {
    @include enter-hover;

    height: 34vh;
    background: url('../../../../shared/ui/images/mineowner/panel.webp') no-repeat bottom;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;

    @media (min-width: $media-desktop-s) {
      left: 20vh;
      right: 20vh;
    }
}

.progress {
  background: url('../../../../shared/ui/images/mineowner/mine-owner-cabin-progress.webp') no-repeat bottom center;
  background-size: cover;
}
