@import 'src/shared';

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.warning {
    padding: 0;

    svg {
        font-size: 64px;
        color: $color-sunrise-yellow-yellow-6;
    }
}

.description {
    color: $color-neutral-neutral-8;
}
