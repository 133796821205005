@import "src/shared";

.page {
  background: url('../../shared/ui/images/service-market/service-market.webp');
  background-size: cover;
  position: relative;
  z-index: 1;
  min-width: 640px;

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 100vh;
    width: 100vw;
    min-width: 640px;
    background: radial-gradient(85.88% 85.88% at -0.62% -5.89%, rgba(0, 0, 0, 0.792) 0%, rgba(0, 0, 0, 0.504) 100%), radial-gradient(84.71% 103.35% at 95.28% 4.33%, rgba(0, 0, 0, 0.712) 0%, rgba(0, 0, 0, 0.496) 88.95%);
    position: absolute;
    z-index: -1;
    margin: 0 auto;

    @media (min-width: $media-desktop-s)  {
      width: 98vw;
    }
  }
}