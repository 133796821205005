@import 'src/shared';

.card.card {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 16px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color $ease-in-out $animation-duration-base;

    &:hover {
        border-color: $color-primary-primary-2;
    }

    &.selected {
        border-color: $color-primary-primary-7;
    }
}

.image {
    max-width: 176px;
    margin-bottom: 8px;
}

.content {
    padding: 16px 16px 8px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
}
