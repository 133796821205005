@import 'src/shared';

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.success {
    padding: 0;

    svg {
        font-size: 64px;
    }
}

.description {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $color-neutral-neutral-9;
}
