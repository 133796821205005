@import 'src/shared';

.logAs {
    font-family: $bai;
    position: fixed;
    right: 0;
    top: 60px;
    padding: 8px 16px;
    background-color: $color-primary-primary-1;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: translateX(calc(100% - 46px));
    color: $color-neutral-neutral-9;
    z-index: 99;

    &Opened {
        transform: translateX(0);
    }

    &Icon {
        margin-right: 16px;
        color: $color-primary-primary-6;
    }
}
