@import 'src/shared';

.item {
  display: flex;
  align-items: center;
  background: $color-neutral-neutral-1;
  color: $color-neutral-neutral-9;
  padding: 16px;
  border: 1px solid $color-neutral-neutral-4;
  border-top: none;
  cursor: pointer;
}