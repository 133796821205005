.plugin {
  width: 134px;
  height: 81px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  white-space: pre-line;
  text-align: center;
}