.rarityMarker {
  height: 12px;
  width: 12px;
  border-radius: 100%;
}

.row {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}