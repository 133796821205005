@import "src/shared";

.actionWrapper {
  width: 204px;
  height: 70px;
  position: fixed;
  bottom: 100px;
  right: 5vw;
  z-index: 10000;
}

.actionName {
  margin-top: 15px;
  margin-left: 13px;
  position: absolute;
}

.timer {
  font-size: 10px;
  color: $color-neutral-neutral-7;
  min-width: 40px;
}

.progress {
  position: absolute;
  margin-top: 33px;
  margin-left: 15px;
  display: flex;
  width: 87%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.icon {
  color: $color-neutral-neutral-7;
}

.controls {
  display: flex;
  gap: 24px;
  justify-content: end;
  width: 100%;
  cursor: pointer;

  .ok {
    color: $color-primary-primary-7
  }
}

// animation from designer @see https://codepen.io/snappyrogue/pen/xxzNdbN
.successSvg {
  animation: stroke-it 3s cubic-bezier(0.45, 0.03, 0.51, 0.95) alternate infinite;
  fill: #1B1B1B;
  stroke: #47FF40;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: arcs;
  stroke-dasharray: 100;
  stroke-dashoffset: 875.6;
  opacity: .7;
}

@keyframes stroke-it {
  from {
    stroke-dashoffset: 875.6;
  }

  to {
    stroke-dashoffset: 0;
  }
}

