.loader.loader {
    color: #fff;
}

.sm {
    font-size: 14px;
}

.md {
    font-size: 16px;
}

.xl {
    font-size: 24px;
}
