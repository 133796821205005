.wrapper {
  height: 74px;
  width: 74px;
  position: relative;
}

.avatar {
  height: 41px;
  width: 41px;
  position: absolute;
  top: 9px;
  left: 0;
  right: 0;
  margin: auto;
}

.userLocationIndicatorInCenter {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}