@import 'src/shared';

.expandedTable {
    :global {
        .ant-table {
            background-color: $color-neutral-neutral-3 !important;
        }

        .ant-table-tbody > tr > td {
            border-color: $color-neutral-neutral-3 !important;
        }
    }
}
