@import "src/shared";

.container {
  width: 100%;
  margin: 25px auto 0;

  @media only screen and (min-width: $media-desktop-s)  {
    margin: 15% auto 0;
  }
}

.description {
  padding: 10px 0;
  text-align: center;

  @media only screen and (min-width: $media-desktop-s)  {
    padding: 16px 0;
  }
}