@import "src/shared";

.nft {
    cursor: auto;
}

.statusText {
    color: $color-neutral-neutral-8;
}

.tableTitle {
    color: $color-neutral-neutral-8;
}