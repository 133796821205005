@import "src/shared";

.hologram {
  text-align: center;
  color: $color-neutral-neutral-9;
  font-size: 12px;
  position: relative;
  z-index: 1;
  display: inline-block;
  white-space: pre-line;
  margin-top: 54vh;
  max-width: 27vw;

  &::after {
    content: '';
    position: absolute;
    left: -10%;
    right: -10%;
    top: -10%;
    bottom: -10%;
    filter: blur(15px);
    border-radius: 15px;
    z-index: -1;
    opacity: 0.25;
  }

  @media (min-width: $media-mobile-oriental)  {
    font-size: 16px;
  }
}

.title {
  text-transform: uppercase;
}