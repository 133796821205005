@import "src/shared";

.container {
  font-family: $bai;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  padding: 0;
  display: flex;
  align-items: center;
  width: fit-content;
  position: absolute;
  padding-left: 16px;
  right: 14px;
  bottom: 300px;
  z-index: 10;

  @media only screen and (min-width: $media-desktop-s)  {
    bottom: 10vh;
    right: 5vw;
  }
}

.close {
  padding: 9px 10px 9px 0;
  color: $color-neutral-neutral-7;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color-neutral-neutral-9;
  padding: 9px 20px 9px 0;

  @media only screen and (min-width: $media-desktop-s)  {
    padding: 9px 40px 9px 0;
  }
}

.button {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: 9px 16px 9px 10px;
  color: $color-primary-primary-6;
  cursor: pointer;
}
