@import "src/shared";

.dropdown {
  background: $color-neutral-neutral-1;
}

.dropdownItem {
  color: $color-neutral-neutral-9;
  font-family: $bai;

  &:hover {
    background: $color-neutral-neutral-2;
  }
}
