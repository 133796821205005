@import "src/shared";

.miningSlots {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-top: 24px;
  padding-bottom: 12px;

  span {
    font-weight: 700;
  }
}

.headerAndStat {
  width: 100%;
}

.wrapper {
  display: flex;
  gap: 32px;
}

.base {
  flex-grow: 1;
}

.areaClaim {
  margin-bottom: 32px;
}

.statistics {
  margin-bottom: 20px;
}

.areaNftImage {
  margin-top: -13px;
}