@import "src/shared";

.homeScreen {
  background-color: $color-cyan-cyan-1;
  background-image: url("../../shared/ui/images/city/hometown.webp");
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
  background-size: cover;

  @media (min-width: $media-desktop-l)  {
    background-image: url("../../shared/ui/images/city/hometown4k.webp");
  }
}

.enterLink {
  @include enter-hover;

  font-family: $orbitron;
  padding: 4px 12px;
  display: inline-block;
  color: $color-neutral-neutral-10 !important;
  cursor: pointer;
  background: rgba(20, 20, 20, 0.4);
  border-bottom: 1px solid $color-neutral-neutral-1;
}

.mineOwnerTitle {
  composes: enterLink;
  margin-top: 100%;
}

.mine {
  @include enter-hover;

  height: 41vh;
  width: 15vw;
  float: right;
  background-image: url("../../shared/ui/images/city/mine.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  cursor: pointer;
  position: relative;

  @media (min-width: $media-tablet)  {
    height: 85vh;
    margin-top: 55px;
  }
}

.hive {
  @include enter-hover;

  margin-left: 8vw;
  margin-top: -1vh;
  height: 50vh;
  width: 20vw;
  background-image: url("../../shared/ui/images/city/hive-building.webp");
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (min-width: $media-tablet)  {
    background-position: center bottom;
  }
}

.serviceMarket {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  position: relative;

  @media (min-width: $media-tablet)  {
    background-position: left;
    margin-top: 83px;
  }
  @media (min-width: $media-desktop-full-hd)  {
    background-position: left;
    margin-top: 140px;
  }

  @media (min-width: $media-wide)  {
    margin-top: 350px;
    margin-left: 63px;
  }
}

.landlordLounge {
  @include enter-hover;

  height: 65vh;
  width: 14vw;
  background-image: url("../../shared/ui/images/city/scyscraper.webp");
  background-size: contain !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right;

  @media (min-width: $media-tablet)  {
    background-position: center;
    height: 69vh;
    margin-top: 30px;
    margin-left: 34px;
  }
  @media (min-width: $media-desktop-full-hd)  {
    margin-top: 21px;
    margin-left: 63px;

  }


}

.engineer {
  @include enter-hover;

  height: 20vh;
  width: 20vw;
  margin-left: 39%;
  background-image: url("../../shared/ui/images/city/specialists.webp");
  background-size: contain !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left;

  @media (min-width: $media-tablet)  {
    background-position: left;
  }
}