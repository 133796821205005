@import "src/shared";

.formField {
  width: 50%;
}

.space {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
}

.deadlineInputsContainer {
  display: grid;
  width: 160px;

  @media (min-width: $media-desktop-s)  {
    grid-template-columns: 175px 175px;
    align-items: end;
  }
}