@import 'src/shared';

.contractor {
    display: flex;
}

.contractorTag {
    margin-left: 12px;
    color: $color-primary-primary-6;
    background-color: $color-neutral-neutral-2;
    border-color: $color-neutral-neutral-5;
}
