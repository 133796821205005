@import 'src/shared';

.miningStats {
    max-width: 1116px;
    margin: 0 auto;
}

.miningStatsTableWrapper {
    margin-right: -16px;
    overflow-x: auto;

    @media (min-width: $media-desktop-s) {
        // .content padding size
        margin-right: -56px;
    }
}

.miningStatsTable {
    width: 1116px;
}

.expandedTable {
    :global {
        .ant-table {
            background-color: $color-neutral-neutral-3 !important;
        }

        .ant-table-tbody > tr > td {
            border-color: $color-neutral-neutral-3 !important;
        }
    }
}

.title.title {
    margin-bottom: 15px;
    color: $color-neutral-neutral-9;
}
