@import "src/shared";

.statScreen {
  @include enter-hover;

  position: absolute;
  right: 0;
  background-image: url("../../../../../shared/ui/images/engineer/display.webp");
  height: 50vh;
  width: 15vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 1;
  cursor: pointer;

  @media (min-width: $media-tablet)  {
    filter: initial;
    background-position: center;
  }
}

.tooltip {
  z-index: 1;
}