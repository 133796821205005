@import 'src/shared';

.mineCrew {
    margin-right: -16px;
    overflow-x: auto;

    @media (min-width: $media-desktop-s) {
        margin-right: -56px;
    }
}

.mineCrewTable {
    width: 1116px;
    margin: 0 auto;
}

.contractors {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    b {
        font-weight: 700;
    }
}
