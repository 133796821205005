@import 'src/shared';

h1.title,
h2.title,
h3.title,
h4.title,
h5.title {
    color: inherit;
}

h1.thin,
h2.thin,
h3.thin,
h4.thin,
h5.thin {
    font-weight: 400;
}

.fontFamilyBai {
    font-family: $bai;
}

.fontFamilyOrbitron {
    font-family: $orbitron;
}

.xs {
    font-size: 12px;
}

.sm {
    font-size: 14px;
}

.md {
    font-size: 16px;
}

.lg {
    font-size: 18px;
}

.bold {
    font-weight: bold;
}

.block {
    display: block;
}

:global {
    .ant-typography {
        &-primary {
            color: $color-primary-primary-6;
        }

        &-white {
            color: $color-neutral-neutral-9;
        }

        &-neutral {
            color: $color-neutral-neutral-1;
        }
    }
}
