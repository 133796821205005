@import "src/shared";

.container {
    padding: 8px 24px 14px;
}

.upgradeTo {
    color: $color-neutral-neutral-7;
}

.upgradeButton {
    width: 100%;
}