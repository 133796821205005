.nftImage {
    border: 0;
    padding: 0;
    cursor: pointer;
    display: block;
    background: transparent;

    &[disabled] {
        cursor: initial;
    }

    &Content {
        position: relative;
        width: 100%;
        height: 100%;

        & > img {
            filter: grayscale(1);
            display: block;
            position: relative;

            --default-background:  #939393;

            &[src=""] {
                background-color: var(--default-background);
            }
        }
    }

    &Learning {
        &Overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: black;
            opacity: 0.7;
            box-shadow: 0 0 10px 3px rgba(245, 201, 19, 0.2);
        }

        &Spin {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &Learned {
        .nftImageContent > img {
            filter: grayscale(0);
        }
    }
}
