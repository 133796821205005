@import "src/shared";

.monitor {
    width: 96%;
    height: 33.5%;
    position: absolute;
    top: 32%;
    color: white;
    clip-path: polygon(
        2.5% 0,
        37.5% 0,
        40.5% 5%,
        61% 5%,
        64% 0,
        97.5% 0,
        100% 5%,
        100% 95%,
        97.5% 100%,
        64% 100%,
        61% 95%,
        40.5% 95%,
        37.5% 100%,
        2.5% 100%,
        0 95%,
        0 5%
    );
    background: #093d6c5c;
    backdrop-filter: blur(3px);

    &Status {
        position: absolute;
        right: 0;
        fill: transparent;
        opacity: 0.36;
        display: flex;
        align-items: center;
        color: white;
    }

    &[data-size="m"] {
        min-width: 312px;
        min-height: 156px;

        .monitorStatus {
            column-gap: 4px;
            margin-top: 6px;
            margin-right: 6px;
        }

        .monitorStatusText {
            color: inherit;
            font-size: 8px;
        }
    }

    padding: 30px;
    @media (min-width: $media-mobile-oriental) {
        padding: 0;

    }

}

.monitorBg {
    height: 100vh;
    background-size: cover, contain;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: absolute;

    @media (min-width: $media-mobile-oriental) {
        background-image: url("../../images/contractor/monitor.webp");
        background-repeat: no-repeat;
    }
}
  