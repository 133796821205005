@import 'src/shared';

.state {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: auto;

    @media only screen and (min-width: $media-desktop-s) {
        height: 100%;
    }
}

.title.title {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 8px;


    @media only screen and (min-width: $media-desktop-s) {
        font-size: 20px;
        line-height: 28px;
    }
}

.content {
    display: none;
    color: $color-neutral-neutral-9;
    margin-bottom: 8px;

    @media only screen and (min-width: $media-tablet) {
        display: block;
    }
}

.bottom {
    margin-top: auto;
}
