@import "src/shared";

.background {
  background: url("../../../shared/ui/images/mineowner/mine-owner-cabin-default-light.webp") no-repeat center;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  position: absolute;

  &::after {
    content: '';
    background: url("../../../shared/ui/images/mineowner/pattern.svg") no-repeat center;
    min-height: 100vh;
    min-width: 100vw;
    background-size: cover;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.panel {
  height: 452px;
  border: 1px solid var(--neutral-neutral-8, #ACACAC);
  background: rgba(15, 14, 14, 0.50);
  backdrop-filter: blur(11px);
  z-index: 1;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: $media-desktop-s)  {
    width: 598px;
  }
}

.left {
  height: 452px;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.right {
  height: 452px;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.sphere {
  width: 259.824px;
  height: 394.981px;
  margin-bottom: 15px;
  opacity: 0.5;
}

.bar {
  width: 93.415px;
  height: 16.08px;
  float: right;
  opacity: .5;
}

.carousel {
  opacity: .5;
  width: 179.176px;
  height: 218.886px;
  margin-top: 40px;
}

.vinyl {
  margin-top: 47px;
  opacity: .5;
}
